export const permissionSubjects = {
  User: 'User',
  LoginAsUser: 'LoginAsUser',
  Parent: 'Parent',
  LoginAsParent: 'LoginAsParent',
  Child: 'Child',
  LoginAsChild: 'LoginAsChild',
  Staff: 'Staff',
  LoginAsStaff: 'LoginAsStaff',
  ChildApplication: 'ChildApplication',
  ChildApplicationData: 'ChildApplicationData',
  ChildApplicationStatus: 'ChildApplicationStatus',
  ChildApplicationStep: 'ChildApplicationStep',
  ChildApplicationSession: 'ChildApplicationSession',
  StaffApplication: 'StaffApplication',
  StaffApplicationData: 'StaffApplicationData',
  StaffApplicationStatus: 'StaffApplicationStatus',
  StaffApplicationStep: 'StaffApplicationStep',
  StaffApplicationSession: 'StaffApplicationSession',
  Charge: 'Charge',
  CustomCharge: 'CustomCharge',
  Payment: 'Payment',
  PaymentContract: 'PaymentContract',
  PaymentContractParameters: 'PaymentContractParameters',
  Wallet: 'Wallet',
  Fee: 'Fee',
  GroupType: 'GroupType',
  Group: 'Group',
  GroupMember: 'GroupMember',
  GroupWizard: 'GroupWizard',
  Report: 'Report',
  FinancialReport: 'FinancialReport',
  ExportPDF: 'ExportPDF',
  ExportCSV: 'ExportCSV',
  EmailTemplate: 'EmailTemplate',
  SMSTemplate: 'SMSTemplate',
  EmailOnEvent: 'EmailOnEvent',
  SingleEmailCommunication: 'SingleEmailCommunication',
  BulkEmailCommunication: 'BulkEmailCommunication',
  SingleSMSCommunication: 'SingleSMSCommunication',
  BulkSMSCommunication: 'BulkSMSCommunication',
  ScheduledEmails: 'ScheduledEmails',
  EmailAudit: 'EmailAudit',
  SMSAudit: 'SMSAudit',
  ApplicationTriggers: 'ApplicationTriggers',
  TagTriggers: 'TagTriggers',
  Form: 'Form',
  FormFolder: 'FormFolder',
  Program: 'Program',
  GeneralProgramSettings: 'GeneralProgramSettings',
  RegistrationChildProgramSettings: 'RegistrationChildProgramSettings',
  RegistrationStaffProgramSettings: 'RegistrationStaffProgramSettings',
  ProgramTasks: 'ProgramTasks',
  ProgramFees: 'ProgramFees',
  ProgramWallets: 'ProgramWallets',
  ProgramTags: 'ProgramTags',
  CamperTabInfo: 'CamperTabInfo',
  TagType: 'TagType',
  Tags: 'Tags',
  Tasks: 'Tasks',
  UserTasks: 'UserTasks',
  Academics: 'Academics',
  Transaction: 'Transaction',
  Page: 'Pages',
}

export const _ = null
